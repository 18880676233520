<template>
    <div id="view_warper" ref="pdfDom" :class="{ pdf_download: pdfDownload }" class="flex_1 cardBg view_warper"
        @click="classKey = ''">
        <p ref="time" class="t-a-r">
            第
            <span :class="{ active: classKey == 'time' }">{{ formData.time || '--' }}</span>
            次勘验
        </p>
        <h1 class="t-a-c">火灾现场勘验笔录</h1>
        <p class="inquiry-time-wrap">
            勘验时间：<span class="content"><span style="flex:1" ref="startTime">
                    <FormatInquiryTime :date="formData.startTime" />
                </span>
                至<span style="flex:1">
                    <FormatInquiryTime :date="formData.endTime" />
                </span></span>
        </p>
        <p class="inquiry-wrap">
            勘验地点：<span class="content">{{ formatAddress(formData.address) }}</span>
        </p>
        <p class="inquiry-wrap">勘验人员姓名、单位、职务（含技术职务）:<span class="content"
                :class="{ active: classKey == 'inquestPersons' }">{{
                    formData.inquestPersonsName || '' }}</span></p>

        <p class="inquiry-wrap">勘验气象条件（天气、风向、温度）：<span class="content" :class="{ active: classKey == 'inquestWeather' }">{{
            formData.inquestWeather || '' }}</span></p>
        <div class="content-render"><span style="float: left;">勘验情况：</span>
            <div ref="inquestContent" :class="{ active: classKey == 'inquestContent' }"
                v-html="formData['inquestContentRender']">
            </div>
        </div>

        <div class="content-render"><span class="content-render-title">一、环境勘验</span>
            <div ref="environInquestContent" :class="{ active: classKey == 'environInquestContent' }"
                v-html="formData['environInquestContentRender']">
            </div>
        </div>

        <div class="content-render"><span class="content-render-title">二、初步勘验</span>
            <div ref="firstInquestContent" :class="{ active: classKey == 'firstInquestContent' }"
                v-html="formData['firstInquestContentRender']">
            </div>
        </div>

        <div class="content-render"><span class="content-render-title">三、细项勘验</span>
            <div ref="secondInquestContent" :class="{ active: classKey == 'secondInquestContent' }"
                v-html="formData['secondInquestContentRender']">
            </div>
        </div>

        <div class="content-render" ref="threeInquestContent"><span class="content-render-title">四、专项勘验</span>
            <div :class="{ active: classKey == 'threeInquestContent' }" v-html="formData['threeInquestContentRender']">
            </div>
        </div>

        <div style="display: flex;">
            <div class="inquiry-wrap" style="flex: 1;">
                勘验负责人（签名）：<span class="content">
                    <span v-if="!formData.proveLeaderSign"></span><img v-else height="40" width="60"
                        :src="formData.proveLeaderSign" alt="" />
                </span>
            </div>
            <div class="inquiry-wrap" style="flex: 1;">
                记录人（签名）：<span class="content"><span v-if="!formData.proveRecorderSign"></span><img v-else height="40"
                        width="60" :src="formData.proveRecorderSign" alt="" /></span>
            </div>
        </div>
        <p class="inquiry-wrap">勘验人（签名）：<span class="content"><span v-if="!formData.provePersonSign"></span><img v-else
                    height="40" width="60" :src="formData.provePersonSign" alt="" /></span></p>
        <div v-for="(item, index) in formData.inquestPartyList" :key="index"
            :class="{ active: classKey == 'inquestPartyList' }">
            <p class="party-item inquiry-wrap" style="display: flex;">

                <span>
                    证人或当事人(签名)：
                    <span class="content" style="min-width: 80px;display: inline-block;">
                        <span v-if="!item.proveDoPersonNameSign">{{ item.proveDoPersonName }}</span>
                        <img v-else height="40" width="60" :src="item.proveDoPersonNameSign" alt="" />
                    </span>
                </span>


                <span style="display: inline-block;min-width: 180px;margin-right: 10px;">
                    <FormatInquiryTime :date="item.proveDoPersonBirth" pattern="{y},{m},{d}" />
                </span>


                <span>
                    身份证件号码：
                    <span class="content">{{ item.proveDoPersonCardNum || '' }}</span>
                </span>

            </p>
            <p class="inquiry-wrap">
                <span>单位或住址：</span><span class="content">{{ item.proveDoPersonOrg || '' }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { checkType } from '@/utils/index';
import FormatInquiryTime from '@/components/FormatInquiryTime'
export default {
    props: {
        formData: {
            type: Object
        },
        pdfDownload: {
            type: Boolean,
        }
    },
    components: { FormatInquiryTime },
    data() {
        return {
            classKey: ''
        }
    },
    computed: {
        formatAddress() {
            return address => {
                if (!address || !address.length) return '--';
                if (checkType(address) === 'string') {
                    return address.split(',')[2]
                } else if (checkType(address) === 'array') {
                    return address[2];
                }
            };
        },
    }
}
</script>
<style scoped lang="scss">
.view_warper {
    font-size: 17px;
    color: #000;
    padding: 15px 30px 30px;
    overflow-y: auto;

    &.pdf_download {
        padding: 63.5px 79.25px;
        font-size: 22px;
    }


    .inquiry-wrap {
        line-height: 2.2;
        font-size: initial;
        clear: both;
        overflow: hidden;

        & .inquiry-label {
            float: left;
        }

        .content {
            position: relative;
            flex: 1;
            text-decoration: underline;
        }

        &.inquiry-none-content {
            display: flex;

            .content {
                &::after {
                    content: '';
                    width: 100%;
                    display: block;
                    border-bottom: 2rpx solid #000;
                    position: absolute;
                    bottom: 18rpx;
                }
            }
        }
    }

    .inquiry-time-wrap {
        .content {
            display: flex;

            &::after {
                border: none;
            }
        }
    }

    .content-render {
        clear: both;
        overflow: hidden;

        .content-render-title {
            width: 100%;
            display: inline-block;
            // text-align-last: justify;
        }
    }

    .party-item {
        .content:first-of-type {
            flex: 0 0 80px;
        }
    }

    p,
    pre {
        margin: 0;
        line-height: 23px;

        &.not-underline {
            span {
                text-decoration: none;
            }
        }
    }

    &>p {
        line-height: 34px;
    }

    pre {
        text-decoration: underline;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    img {
        vertical-align: middle;
        height: 25px;
    }
}

::v-deep .content-render {
    p {
        margin: 0;
        word-break: break-all;
    }

    &>div {
        display: inline;
        text-decoration: underline;
    }
}
</style>