<template>
    <div class="inquest-page page template-page ">
        <div v-loading.fullscreen.lock="loading" class="content" flex="main:justify">
            <div v-if="!pdfDownload" ref="leftPage" class="flex_1 m-r-10 b-r p-l-10 p-t-10 p-r-10 p-b-20 cardBg left-page">
                <avue-form ref="form" v-model="formData" :option="option" :upload-before="uploadBefore"
                    :upload-preview="uploadPreview">
                    <template slot="time" slot-scope="{ value, column, size, disabled }">
                        <el-input-number style="width:100%" v-model="formData.time" :step="1" :min="1"
                            step-strictly></el-input-number>
                    </template>
                    <template slot="archivesQuestionsList" slot-scope="scope">
                        <div flex>
                            <div class="flex_1 m-r-10">
                                <avue-select v-model="scope.value" placeholder="请选择" type="tree" :dic="questionsList"
                                    @change="questionChange" />
                            </div>
                            <el-button :disabled="!Object.keys(currentItem).length" type="primary"
                                @click="questionDialog = !questionDialog">{{ questionDialog ? '隐藏' : '显示' }}</el-button>
                        </div>
                    </template>
                    <template slot="evidenceChain" slot-scope="{}">
                        <el-button type="primary" @click="visibleDialog = !visibleDialog">{{ visibleDialog ? '隐藏' : '显示' }}
                        </el-button>
                    </template>
                    <template slot="imageList" slot-scope="scope">
                        <!-- 图片列表 -->
                        <div flex>
                            <div class="flex_1 m-r-10">
                                <avue-select v-model="scope.value" placeholder="请选择" type="tree" :dic="imageList"
                                    @change="imageListChange" />
                            </div>
                        </div>
                    </template>
                    <template slot="proveLeaderSign" slot-scope="scope">
                        <div flex="cross:center main:justify">
                            <img v-if="scope.value" class="flex_1" :src="scope.value" alt="" height="32" />
                            <el-link class="margin_left_10" type="primary"
                                @click="; (signatureVisible = true), (currentKey = 'proveLeaderSign')">签名</el-link>
                        </div>
                    </template>
                    <template slot="proveRecorderSign" slot-scope="scope">
                        <div flex="cross:center main:justify">
                            <img v-if="scope.value" class="flex_1" :src="scope.value" alt="" height="32" />
                            <el-link class="margin_left_10" type="primary"
                                @click="; (signatureVisible = true), (currentKey = 'proveRecorderSign')">签名</el-link>
                        </div>
                    </template>
                    <template slot="provePersonSign" slot-scope="scope">
                        <div flex="cross:center main:justify">
                            <img v-if="scope.value" class="flex_1" :src="scope.value" alt="" height="32" />
                            <el-link class="margin_left_10" type="primary"
                                @click="; (signatureVisible = true), (currentKey = 'provePersonSign')">签名</el-link>
                        </div>
                    </template>
                    <template slot="proveDoPersonNameSign" slot-scope="scope">
                        <div flex="cross:center main:justify">
                            <div flex="cross:center">
                                <img v-if="scope.value" :src="scope.value" alt="" height="50" style="margin-right: 5px;" />
                                <span v-if="scope.row.proveDoPersonName">{{ scope.row.proveDoPersonName }}</span>
                                <el-link class="margin_left_10" type="primary"
                                    @click="; (signatureVisible = true), (currentKey = `inquestPartyList.${scope.row.$index}.proveDoPersonNameSign`)">
                                    签名</el-link>
                            </div>
                            <el-upload style="margin-right: 30px;" :action="cardUploadUrl" :headers="headers"
                                :show-file-list="false" :before-upload="() => {
                                    ; (loading = true), (currentKey = `inquestPartyList.${scope.row.$index}`)
                                }
                                    " :on-success="handleOnSuccess">
                                <el-button size="mini" type="success" plain style="margin-left: 8px">上传身份证图片</el-button>
                            </el-upload>
                        </div>
                    </template>

                    <template slot="imgUrlType" slot-scope="{ file }">
                        <div flex="main:justify cross:center">
                            <video width="250" height="56" controls="" name="media">
                                <source :src="file.url" type="audio/flac" />
                            </video>
                            <div flex>
                                <el-button plain size="mini" type="primary" @click="videoDiscern(file.url)">转文字</el-button>
                                <el-button plain size="mini" type="danger"
                                    @click="handleDeleteVideo(file.uid)">删除</el-button>
                            </div>
                        </div>
                    </template>
                    <!-- 富文本框 -->
                    <template v-for="(item, key) in contentArr" :slot="item" slot-scope="{}">
                        <mavon-editor :key="key" :ref="'mavonEditor' + item" v-model="formData[item]" :toolbars="toolbars"
                            :subfield="false" @change="(v, r) => editorChange(v, r, item)" />
                    </template>
                </avue-form>
            </div>
            <InquestTemplate class="template" :formData="formData" :pdfDownload="pdfDownload" />
        </div>
        <DownloadBtn class="download-btn" :pdfDownload="pdfDownload" title="勘验笔录" :keyId="params.keyId" :formData="formData"
            :moduleName="currentModule.moduleName" @saveData="submitFormBackBefore" />
        <FooterBtn class="footer" :pdfDownload="pdfDownload" :submitDis="false" @submit="submitForm"
            @cancel="handleCancel" />
        <SignatureDialog v-model="signatureVisible" @submit="generate" />
        <div v-show="questionDialog && !pdfDownload" class="right-dialog" :class="{ 'put-away': isPutAway }">
            <el-card class="content">
                <div v-for="(question, index) in currentItem.archivesQuestionsList" :key="index">
                    <div class="item question" :class="questionsActive.includes(index) ? 'active' : ''"
                        @click="assignmentQuestion(question)">
                        <p>
                            <span>{{ question.question }}</span>
                        </p>
                    </div>
                </div>
                <p class="end-line">- - - - - - - - 我是有底线的 - - - - - - - - -</p>
            </el-card>
            <div class="hide-box" @click="putAwayRight">
                <i :class="isPutAway ? 'el-icon-caret-left' : 'el-icon-caret-right'"></i>
            </div>
        </div>

        <div v-show="visibleDialog && !pdfDownload" class="module-dialog" :class="{ 'put-away': modulePutAway }">
            <el-card class="content">
                <el-tabs v-model="activeName" class="tabs" type="card" @tab-click="tabClick">
                    <el-tab-pane v-for="moduleTab in moduleTabList" :key="moduleTab.value" :label="moduleTab.name"
                        :name="moduleTab.value">
                        <keep-alive>
                            <component :is="componentName(moduleTab.value)" :data="moduleObjList[moduleTab.value]"
                                :dict-id="moduleTab.value" />
                            <!-- <Notice :dict-id="moduleTab.value" :data1="moduleObjList[moduleTab.value]" /> -->
                        </keep-alive>

                    </el-tab-pane>
                </el-tabs>

            </el-card>
            <div class="hide-box" @click="modulePutAwayTop">
                <i :class="modulePutAway ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
            </div>
        </div>

        <div v-show="imageListDialog && !pdfDownload" class="leftUp-dialog" :class="{ 'put-away': isImgPutAway }">
            <el-card class="content">
                <el-carousel ref="carousel" height="180px" :autoplay="false" style="width: 100%; height: 100%">
                    <el-carousel-item v-for="(item, index) in imageList" :key="index" :name="item.value">
                        <img :src="item.value" :alt="item.label" @click="fillContent(item)" />
                    </el-carousel-item>
                </el-carousel>
                <div class="hide-box" @click="putAwayTop">
                    <i :class="isImgPutAway ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
                </div>
            </el-card>
        </div>
        <PdfDialog v-model="pdfDialog.show" :pdf-url="pdfDialog.url" />
    </div>
</template>

<script>
import DataFlow from '@/mixins/DataFlow'
import { getToken } from '@/utils/auth'
import PdfDialog from '@/components/PdfDialog/PdfDialog.vue';
import { checkType } from '@/utils/index';
import { pickerOptions } from '@/const/avue'
import FooterBtn from '@/components/FooterBtn'
import SignatureDialog from '@/components/SignatureDialog'
import DownloadBtn from '@/components/DownloadBtn'
import InquestTemplate from '@/components/Template/inquest.vue'

export default {
    name: 'Inquest',
    mixins: [DataFlow],
    components: {
        FooterBtn, SignatureDialog, DownloadBtn, InquestTemplate, PdfDialog,
        Notice: () => import('./components/notice.vue'),
        Record: () => import('./components/record.vue'),
        Inventory: () => import('./components/inventory.vue'),
        Loss: () => import('./components/loss.vue'),
    },
    data() {
        return {
            signatureVisible: false,
            currentKey: '',
            classKey: '',
            questionsList: [],
            questionDialog: false,
            isPutAway: false,
            isImgPutAway: false,
            currentItem: {},
            imageList: [],
            imageListDialog: false,
            contentArr: ['inquestContent', 'environInquestContent', 'firstInquestContent', 'secondInquestContent', 'threeInquestContent'],
            focusKey: '',
            toolbars: {
                readmodel: false, // 沉浸式阅读
                defaultOpen: 'edit',
                help: true, // 帮助
                subfield: true, // 是否需要分栏
                fullscreen: true, // 全屏编辑
                undo: true, // 上一步
                trash: true // 清空
            },
            visibleDialog: true,
            activeName: '',
            tabActiveName: '',
            moduleTabList: [],
            cardList: [],
            moduleObjList: {},
            modulePutAway: true,
            pdfDownload: false,
            isEdit: false,
            isDownload: false,
            pdfDialog: {
                show: false,
                url: ''
            },
            cachedComponents: [],
            componentKey: 0,
            focusTime: 0,
            blurTime: 0,
            currentMavonName: ''
        }
    },
    computed: {
        option() {
            // const _token = this.$route.query.token
            return {
                submitBtn: false,
                emptyBtn: false,
                group: [
                    {
                        label: '勘验相关',
                        prop: 'inquest',
                        icon: 'el-icon-edit-outline',
                        arrow: false,
                        column: [
                            {
                                label: '人员',
                                prop: 'inquestPersons',
                                span: 24,
                                type: 'select',
                                multiple: true,
                                dicUrl: '/system/user/list?isDic=true',
                                dicHeaders: this.headers,
                                name: 'inquestPersons',
                                props: {
                                    res: 'rows',
                                    label: 'computedLabel',
                                    value: 'userId'
                                },
                                dicFormatter: data => {
                                    let rows = data.rows
                                    let arr = rows.map((ele, i) => {
                                        return {
                                            ...ele,
                                            computedLabel: `${ele.dept?.deptName || '游客'}-${ele.nickName}`,
                                            userId: ele.userId + '',
                                            desc: ele.dept?.deptName
                                        }
                                    })
                                    return arr
                                },
                                dataType: 'string',
                                change: ({ item }) => {
                                    console.log(item);
                                    let str = ''
                                    item.forEach(user => {
                                        if (this.formData.inquestPersonsName == '') {
                                            str = user.computedLabel
                                        } else {
                                            str += ' ' + user.computedLabel
                                        }

                                    })
                                    this.formData.inquestPersonsName = str
                                }
                            },
                            {
                                label: '气象条件',
                                prop: 'inquestWeather',
                                span: 24
                            },
                            {
                                label: '勘验情况',
                                placeholder: '上传录音后，可识别成文字',
                                prop: 'inquestContent',
                                span: 24,
                                type: 'textarea',
                                value: '',
                            }
                        ]
                    },
                    {
                        label: '环境勘验',
                        prop: 'environ',
                        icon: 'el-icon-edit-outline',
                        arrow: false,
                        column: [
                            {
                                label: '勘验情况',
                                placeholder: '上传录音后，可识别成文字',
                                prop: 'environInquestContent',
                                span: 24,
                                type: 'textarea'
                            }
                        ]
                    },
                    {
                        label: '初步勘验',
                        prop: 'first',
                        icon: 'el-icon-edit-outline',
                        arrow: false,
                        column: [
                            {
                                label: '勘验情况',
                                placeholder: '上传录音后，可识别成文字',
                                prop: 'firstInquestContent',
                                span: 24,
                                type: 'textarea'
                            }
                        ]
                    },
                    {
                        label: '细项勘验',
                        prop: 'second',
                        icon: 'el-icon-edit-outline',
                        arrow: false,
                        column: [
                            {
                                label: '勘验情况',
                                placeholder: '上传录音后，可识别成文字',
                                prop: 'secondInquestContent',
                                span: 24,
                                type: 'textarea'
                            }
                        ]
                    },
                    {
                        label: '专项勘验',
                        prop: 'three',
                        icon: 'el-icon-edit-outline',
                        arrow: false,
                        column: [
                            {
                                label: '勘验情况',
                                placeholder: '上传录音后，可识别成文字',
                                prop: 'threeInquestContent',
                                span: 24,
                                type: 'textarea'
                            }
                        ]
                    },
                    {
                        column: [{
                            label: '实验报告',
                            prop: 'experimentReport',
                            type: 'upload',
                            loadText: '附件上传中，请稍等',
                            span: 24,
                            accept: '.pdf',
                            action: '/common/upload?isDic=true',
                            headers: this.headers,
                            dataType: 'string',
                            propsHttp: {
                                res: 'data'
                            }
                        },
                        {
                            label: '检测报告',
                            prop: 'detectionReport',
                            type: 'upload',
                            loadText: '附件上传中，请稍等',
                            span: 24,
                            accept: '.pdf',
                            action: '/common/upload?isDic=true',
                            headers: this.headers,
                            dataType: 'string',
                            propsHttp: {
                                res: 'data'
                            }
                        }]
                    },
                    {
                        label: '证明相关',
                        prop: 'prove',
                        icon: 'el-icon-view',
                        arrow: false,
                        column: [
                            {
                                label: '勘验负责人',
                                prop: 'proveLeaderSign'
                            },
                            {
                                label: '记录人',
                                prop: 'proveRecorderSign'
                            },
                            {
                                label: '勘验人',
                                prop: 'provePersonSign'
                            },
                            {
                                label: '当事人',
                                prop: 'inquestPartyList',
                                type: 'dynamic',
                                index: false,
                                span: 24,
                                children: {
                                    align: 'center',
                                    type: 'form',
                                    headerAlign: 'center',
                                    index: false,
                                    column: [
                                        {
                                            label: '当事人',
                                            prop: 'proveDoPersonNameSign',
                                            span: 24
                                        },
                                        {
                                            label: '当事人姓名',
                                            prop: 'proveDoPersonName',
                                            span: 24,
                                            display: false
                                        },
                                        {
                                            label: '出生日期',
                                            prop: 'proveDoPersonBirth',
                                            type: 'date',
                                            format: 'yyyy年MM月dd日',
                                            valueFormat: 'yyyy-MM-dd',
                                            editable: false,
                                            span: 24
                                        },
                                        {
                                            label: '证件号码',
                                            prop: 'proveDoPersonCardNum',
                                            span: 24
                                        },
                                        {
                                            label: '单位或住址',
                                            prop: 'proveDoPersonOrg',
                                            span: 24
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ],

                column: [
                    {
                        label: '勘验次数',
                        prop: 'time',
                        span: 24,
                        type: 'input-number',
                        value: 1,
                        controlsPosition: 'left',
                        min: 1
                    },
                    {
                        label: '开始时间',
                        prop: 'startTime',
                        type: 'datetime',
                        editable: false,
                        pickerOptions,
                        focus: () => {
                            this.$refs.form.$nextTick(() => {
                                const inputs = document.querySelectorAll('.el-date-picker__editor-wrap input')
                                    ;[...inputs].forEach(item => {
                                        item.setAttribute('readonly', true)
                                    })
                            })
                        }
                    },
                    {
                        label: '结束时间',
                        prop: 'endTime',
                        type: 'datetime',
                        editable: false,
                        pickerOptions
                    },
                    {
                        label: '勘验地点',
                        prop: 'address',
                        type: 'map',
                        span: 24,
                        params: {
                            zoom: 10
                        },
                        dataType: 'string'
                    },
                    {
                        span: 24,
                        label: '证据链',
                        prop: 'evidenceChain'
                    }
                ]
            }
        },
        cardUploadUrl() {
            return this.baseUrl + '/archives/notice/discern'
        },
        headers() {
            return {
                Authorization: 'Bearer ' + getToken()
            }
        },
        componentName() {
            return (dictId) => {
                const obj = {
                    1: 'Notice',
                    2: 'Record',
                    3: 'Inventory',
                    10: 'Loss'
                }
                return obj[dictId] || 'Notice'
            }
        },
        isPad() {
            return window.innerWidth <= 1200
        },
        formatAddress() {
            return address => {
                if (!address || !address.length) return '--';
                if (checkType(address) === 'string') {
                    return address.split(',')[2]
                } else if (checkType(address) === 'array') {
                    return address[2];
                }
            };
        },
    },
    created() {
        window.addEventListener('keydown', this.handleKeyDown, false) //开启监听键盘按下事件
        this.getQuestionsList()
    },
    mounted() {
        this.slideBanner()
        this.$nextTick(() => {
            Object.keys(this.formData).map(key => {
                this.$watch(
                    `formData.${key}`,
                    () => {
                        this.classKey = key
                        if (this.$refs[key]) {
                            this.$refs[key].scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                            })
                        }
                    },
                    { deep: true }
                )
            })
        })
        if (this.$refs.form) {
            this.$refs.form.$nextTick(() => {
                setTimeout(() => {
                    const inputs = document.querySelectorAll('.el-date-picker__editor-wrap')
                }, 1000)
            })
            this.contentArr.forEach(item => {
                let elRef = this.$refs['mavonEditor' + item][0]
                let textArea = elRef.$refs.vNoteTextarea.$refs.vTextarea
                textArea.addEventListener('focus', (e) => this.mavonFocus(item, elRef, e))
                textArea.addEventListener('blur', (e) => this.mavonBlur(item, elRef, e))
                elRef.$el.addEventListener('mousedown', (e) => this.mavonMouseDown(item, textArea, e))
            })
        }


    },
    methods: {
        beforeHandleData(newVal) {
            this.pdfDownload = newVal.pdfDownload == 'true'
            this.isEdit = newVal.isEdit == 'true'
        },
        afterHandleData() {
            setTimeout(() => {
                this.classKey = ''
                if (this.$refs.leftPage) {
                    this.$refs.leftPage.scrollTop = 0
                }
            })
            this.getModuleList(this.params.archivesId)
        },
        uploadBefore(file, done, loading) {
            const isLt50M = file.size / 1024 / 1024 < 50

            if (!isLt50M) {
                this.$message.error('上传的文件大小超出限制的文件大小！允许的文件最大大小是：50MB！')
                return loading()
            }
            if (file.type == '') {
                return
            }
            const isFileType = file.type == 'application/pdf'
            if (!isFileType) {
                this.$message.error(`只能上传文档格式!`);
                return loading()
            }
            done()
        },
        uploadPreview(file, column, done) {
            if (this.isPdf(file.url)) {
                this.pdfDialog.show = true
                this.pdfDialog.url = file.url
            } else {
                done()
            }
        },
        findContentDom(el) {
            const childElement = el.$el
            // 定义要查找的父节点的类名
            const desiredParentClass = 'avue-form__item--';

            // 开始查找父节点
            let parent = childElement.parentNode;

            while (parent) {
                // 检查当前父节点是否具有所需的类名
                if (parent.classList.contains(desiredParentClass)) {
                    break;
                }

                // 继续向上查找
                parent = parent.parentNode;
            }
            const label = parent.previousSibling
            return parent
        },
        mavonFocus(name, el, e) {
            const parentDom = this.findContentDom(el)
            parentDom.classList.add('mavon-active');
            this.focusKey = name
            if (el.d_value == '') {
                el.d_value += '问：'
                this.formData[name + 'Num']++
            }
        },
        mavonBlur(name, el, e) {
            const labelEl = this.findContentDom(el)
            labelEl.classList.remove('mavon-active');
        },
        mavonMouseDown(name, inputEl, e) {
            if (e.target.nodeName !== 'TEXTAREA') {
                e.preventDefault(); // 阻止默认行为（防止输入框失去焦点）
                inputEl.focus(); // 将焦点设置回输入框
            }
        },
        handleKeyDown(e) {
            let key = null
            if (window.event === undefined) {
                key = e.keyCode
            } else {
                key = window.event.keyCode
            }

            if (key === 187) {
                //触发的事件
                if (this.focusKey) {
                    let el = this.$refs['mavonEditor' + this.focusKey][0]
                    el.$nextTick(() => {
                        this.formData[this.focusKey + 'Num']++
                        if (this.formData[this.focusKey + 'Num'] % 2 == 0) {
                            if (this.formData[this.focusKey + 'Num'] == 2) {
                                el.d_value += '\n答：'
                            } else {
                                el.d_value += '\n答：'
                            }
                        } else {
                            el.d_value += '\n问：'
                        }
                        e.preventDefault()
                    })
                }
            }
        },
        getImageList() {
            fetch(`${this.baseUrl}/archives/notice/${this.$route.query.imageListId}`, {
                method: 'GET',
                headers: {
                    ...this.headers,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                mode: 'cors'
            })
                .then(e => e.json())
                .then(res => {
                    if (res.code == 200) {
                        if (!res.data.jsonStr) return
                        const arr = JSON.parse(res.data.jsonStr)
                        const list = []
                        arr.forEach(item => {
                            if (item.isNoGroup) {
                                item.imgFileList.forEach(image => {
                                    list.push(image)
                                })
                            }
                        })
                        this.imageList = list.map((image, index) => {
                            return {
                                label: `图${index + 1}`,
                                value: image.url
                            }
                        })
                    }
                })
        },
        imageListChange(e) {
            if (e != '') {
                this.$refs.carousel.setActiveItem(e)
                this.imageListDialog = true
                this.isImgPutAway = false
            } else {
                this.imageListDialog = false
            }
        },
        fillContent(item) {
            if (this.contentArr.includes(this.classKey)) {
                this.formData[this.classKey] += `（${item.label}）`
            }
        },
        putAwayTop() {
            this.isImgPutAway = !this.isImgPutAway
        },
        async getQuestionsList() {
            if (this.questionsList.length) return this.questionsList
            return await fetch(`${this.baseUrl}/archives/tem/list?type=1&pageNum=1&pageSize=99`, {
                method: 'GET',
                headers: {
                    ...this.headers,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                mode: 'cors'
            })
                .then(e => e.json())
                .then(res => {
                    if (res.code == 200) {
                        this.questionsList = res.rows.map(tem => {
                            return {
                                label: tem.name,
                                value: tem.templateId,
                                ...tem
                            }
                        })
                        return this.questionsList
                    }
                    return []
                })
                .catch(e => {
                    window.parent.postMessage('error', '*')
                })
        },
        async questionChange(e) {
            this.formData.archivesQuestionsList = e
            if (!this.init) {
                this.formData.content = ''
                this.questionsActive = []
            }

            if (e != '') {
                this.isPutAway = false
                const list = await this.getQuestionsList()
                let obj = {}
                list.forEach(item => {
                    if (item.templateId == e) {
                        obj = item
                    }
                })
                this.currentItem = obj
            }
            this.questionDialog = e != ''
        },
        // 笔录填充
        assignmentQuestion(item) {
            this.$Clipboard({
                text: item.question
            })
                .then(() => {
                    this.$message.success('复制成功')
                })
                .catch(() => {
                    this.$message.error('复制失败')
                })
        },
        putAwayRight() {
            this.isPutAway = !this.isPutAway
        },
        editorChange(value, render, key) {
            const that = this.$refs['mavonEditor' + key][0]
            that.$nextTick(() => {
                const el = that.$refs.vShowContent.innerHTML
                this.$set(this.formData, key + 'Render', el)
            })
        },
        async videoDiscern(url) {
            this.loading = true
            const res = await fetch(`${this.baseUrl}/archives/notice/videoDiscern`, {
                method: 'post',
                headers: this.headers,
                body: url
            })
                .then(e => e.json())
                .catch(() => (this.loading = false))
            this.loading = false
            this.formData.inquestContent = res.data.result.join()
        },
        handleDeleteVideo(uid) {
            const index = this.formData.imgUrl.findIndex(item => item.uid === uid)
            this.formData.imgUrl.splice(index, 1)
        },
        handleOnSuccess(res) {
            if (!res.data.words_result_num) {
                this.$message.warning('无法识别，请上传清晰的证件照片')
            } else {
                this.$message.success('识别成功')
                const {
                    姓名: { words: proveDoPersonName },
                    出生: { words: proveDoPersonBirth },
                    公民身份号码: { words: proveDoPersonCardNum },
                    住址: { words: proveDoPersonOrg }
                } = res.data.words_result
                const day = `${proveDoPersonBirth.slice(0, 4)}, ${parseInt(proveDoPersonBirth.slice(4, 6))}, ${proveDoPersonBirth.slice(6, 8)}`
                const arr = this.currentKey.split('.')
                Object.assign(this.formData[arr[0]][arr[1]], {
                    proveDoPersonCardNum,
                    proveDoPersonOrg,
                    proveDoPersonName,
                    proveDoPersonBirth: this.parseTime(new Date(day))
                })
            }
            this.loading = false
        },
        generate(base64) {
            const arr = this.currentKey.split('.')
            arr.reduce((prev, curr, index) => {
                if (index === arr.length - 1) {
                    prev[curr] = base64
                }
                return prev[curr]
            }, this.formData)
        },
        initForm() {
            const archives = this.currentRow?.archives || {};
            console.log(this.archivesData);
            const { fireAddress, weather, doPerson, doOtherPerson } = this.archivesData
            let initForm = {
                time: 1,
                startTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                endTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
                address: fireAddress,
                inquestPersons: `${doPerson}${doOtherPerson ? ',' + doOtherPerson : ''}`,
                inquestPersonsName: ``,
                inquestWeather: weather,
                inquestContent: '',
                inquestContentRender: '',
                inquestContentNum: 0,
                environInquestContent: '',
                environInquestContentNum: 0,
                environInquestContentRender: '',
                firstInquestContent: '',
                firstInquestContentNum: 0,
                firstInquestContentRender: '',
                secondInquestContent: '',
                secondInquestContentNum: 0,
                secondInquestContentRender: '',
                threeInquestContent: '',
                threeInquestContentNum: 0,
                threeInquestContentRender: '',
                proveLeaderSign: '',
                proveRecorderSign: '',
                provePersonSign: '',
                inquestPartyList: [],
                createTime: ''
            }
            return initForm
        },
        submitFormBefore() {
            if (this.formData.address && checkType(this.formData.address) == 'array') {
                this.formData.address = this.formData.address.join(',')
            }
        },
        getModuleList(archivesId) {
            fetch(`${this.baseUrl}/archives/archives/getModule/${archivesId}`, {
                method: 'GET',
                headers: this.headers
            })
                .then(e => e.json())
                .then(res => {
                    if (res.code == 200) {
                        this.moduleTabList = res.data.filter(item => {
                            return !['勘验笔录', '事故认定书'].includes(item.name)
                        })
                        this.activeName = res.data[0].value
                        this.getModuleDetailData(archivesId) // 获取当前模块数据
                    }
                })
        },
        getModuleDetailData(archivesId) {
            fetch(`${this.baseUrl}/archives/notice/list?archivesId=${archivesId}`, {
                method: 'GET',
                headers: {
                    ...this.headers,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(e => e.json())
                .then(res => {
                    if (res.code == 200 && res.rows.length) {
                        let rows = res.rows
                        let obj = {}
                        rows.forEach(item => {
                            obj[item.dictId] = item.mapList
                        })
                        this.moduleObjList = obj
                        this.cardList = res.rows[0].mapList
                    }
                })
        },
        tabClick(e) {
            // this.cardList = this.moduleObjL
            this.cachedComponents = [this.componentName(e.name)]
            this.componentKey += 1; // 每次切换标签页时增加 key 的值，强制重新渲染组件
        },
        isActiveTab(tabValue) {
            return this.activeName === tabValue;
        },
        modulePutAwayTop() {
            this.modulePutAway = !this.modulePutAway
        },
        // 滑动切换
        slideBanner() {
            // 选中的轮播图
            var box = document.querySelector('.el-carousel__container')
            var startPoint = 0
            var stopPoint = 0
            // 重置坐标
            var resetPoint = function () {
                startPoint = 0
                stopPoint = 0
            }
            // 手指按下
            box.addEventListener('touchstart', function (e) {
                // 手指点击位置的X坐标
                startPoint = e.changedTouches[0].pageX
            })
            // 手指滑动
            box.addEventListener('touchmove', function (e) {
                // 手指滑动后终点位置X的坐标
                stopPoint = e.changedTouches[0].pageX
            })
            // 当手指抬起的时候，判断图片滚动离左右的距离
            const that = this
            box.addEventListener('touchend', function (e) {
                if (stopPoint == 0 || startPoint - stopPoint == 0) {
                    resetPoint()
                    return
                }
                if (startPoint - stopPoint > 0) {
                    resetPoint()
                    that.$refs.carousel.next()
                    return
                }
                if (startPoint - stopPoint < 0) {
                    resetPoint()
                    that.$refs.carousel.prev()
                    return
                }
            })
        }
    }
}
</script>
<style lang="scss">
.leftUp-dialog,
.right-dialog {
    &>.el-card {

        &>.el-card__body {
            padding: 0;
            overflow-y: auto;
            height: calc(100vh - 120px);
        }
    }
}
</style>
<style lang="scss" scoped>
.module-dialog,
.leftUp-dialog,
.right-dialog {
    z-index: 2000;
    width: 400px;
    position: absolute;
    right: 8px;
    top: 0;
    transition: 0.5s;

    .content {
        position: relative;
        font-size: 12px;
        padding-left: 30px;
    }

    .hide-box {
        position: absolute;
        transform: translateX(-50%);
        z-index: 2001;
        font-size: 28px;
        cursor: pointer;
    }

    .item {
        cursor: pointer;
        padding: 3px 3px;
        background-color: #f1f2f3;
        line-height: 26px;
        font-size: 12px;
        color: #000;
        padding-left: 5px;
        box-sizing: border-box;
        margin-bottom: 6px;

        &.question {
            color: blue;
        }

        &.answer {
            color: #333;
        }

        p {
            position: relative;
            padding: 0;
            margin: 0;
        }

        &.active {
            background-color: #74b0ef;
            color: #fff;
        }
    }

    .end-line {
        text-align: center;
        color: #bbb;
        padding: 8px 0;
    }
}



.right-dialog {
    &.put-away {
        right: -370px;
    }

    .hide-box {
        top: 0;
        right: 360px;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.leftUp-dialog {
    left: 100px;
    width: 360px;

    &.put-away {
        top: -200px;
    }

    .el-card__body {
        height: 180px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .hide-box {
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
    }
}



.inquest-page {

    ::v-deep .avue-dynamic {
        .avue-dynamic__row {
            button.avue-dynamic__menu {
                top: 6.5%;
            }
        }
    }

    ::v-deep .mavon-active {
        padding-top: 6px;
        font-weight: bold;

        .el-form-item__label {
            color: $main-color;
        }

        .el-form-item__content {
            box-shadow: $type-primary-light 0px 2px 10px 0px;
        }
    }

    ::v-deep .module-dialog {
        width: 50%;

        &>.content {
            padding-bottom: 30px;

            &>.el-card__body {
                padding: 0;
                height: calc(100vh - 120px);
            }
        }



        .tabs {
            height: 100%;

            .el-tabs__content {
                height: calc(100% - 41px - 15px);
            }

            .el-tab-pane {
                height: 100%;

                &>div {
                    height: 100%;

                    .container {
                        height: 100%;
                    }
                }
            }
        }




        .hide-box {
            width: 50px;
            text-align: center;
            bottom: -25px;
        }

        &.put-away {
            top: calc(95px - 100vh);
        }

        .tabs {
            .el-tabs__nav-scroll {
                overflow-x: auto;
                // ie10+
                -ms-overflow-style: none;
                // firefox
                overflow: -moz-scrollbars-none;

                // chrome 和 safari
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
}
</style>
